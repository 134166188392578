<template>
	<Modal
		title="查看流程列表"
		:value="visible"
		width="1000"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<CtmsDataGrid
			:columns="flowColumns"
			:height="dataGridHeight"
			:data="flowList"
			:page="false"
		></CtmsDataGrid>
		<div slot="footer">
			<Button style="margin-right: 8px" type="primary" @click="onCancel"
				>关闭
			</Button>
		</div>
	</Modal>
</template>

<script>
import processApi from "@/api/project/process"
import { mapState } from "vuex"
import { paramsStringify } from "@/utils/util"

const { apiGetProcessList } = processApi

export default {
	name: "ProjectFormTemplateView",
	props: ["visible", "flowId", "fileId", "isNeedMark"],
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 146
			}
		})
	},
	data() {
		return {
			loading: false,
			flowList: [],
			flowColumns: [
				{
					title: "流程名称",
					key: "name",
					minWidth: 160
				},
				{
					title: "启动用户",
					key: "startUserName"
				},
				{
					title: "启动时间",
					key: "startTime"
				},
				{
					title: "状态",
					render: (h, params) =>
						h("span", {}, params.row.endTime ? "已完成" : "进行中")
				},
				{
					title: "操作",
					key: "action",
					minWidth: 100,
					renderButton: () => [
						{
							label: "预览",
							on: {
								click: this.seeDetail
							}
						},
						{
							label: "下载",
							on: {
								click: this.downloadTemplate
							}
						}
					]
				}
			]
		}
	},
	watch: {
		visible(val) {
			if (val && this.flowId) {
				this.getProcessList()
			}
		}
	},
	methods: {
		async getProcessList() {
			const res = await apiGetProcessList({
				projectId: this.$route.params.projectId,
				modelId: this.flowId,
				pageNum: 1,
				pageSize: 999
			})
			if (res) {
				this.flowList = res.data.list
			}
		},
		seeDetail({ row: { id } }) {
			window.open(
				`${this.$baseUrl}/common/form-file/${this.fileId}/filled?${paramsStringify(
					{
						id: this.fileId,
						procInstId: id,
						projectId: this.$route.params.projectId,
						token: this.$store.state.user.token,
						isNeedMark: this.isNeedMark
					}
				)}`
			)
		},
		// 下载PDF
		downloadTemplate({ row: { id } }) {
			window.open(
				`${this.$baseUrl}/common/form-file/${this.fileId}/filled?${paramsStringify(
					{
						id: this.fileId,
						procInstId: id,
						projectId: this.$route.params.projectId,
						token: this.$store.state.user.token,
						isDownload: 1
					}
				)}`
			)
		},
		onCancel() {
			this.$emit("onCancel")
		}
	},
	mounted() {}
}
</script>

<style scoped></style>
