import request from "@/utils/request"

const moduleName = "/common/form-file"

export default {
	// 获取文件列表
	getList: data => {
		if (!data) throw new Error("Invalid data parameter")
		return request(`${moduleName}/list`, {
			params: data
		})
	},

	// 获取项目表单文件列表
	getTemplateList: data => {
		if (!data) throw new Error("Invalid data parameter")
		return request(`${moduleName}/project-list`, {
			params: data
		})
	},

	// 上传项目表单文件
	uploadFile: (data, params) => {
		if (!data || !params) throw new Error("Invalid data or params parameter")
		return request(`${moduleName}/upload`, {
			method: "post",
			body: data,
			params
		})
	},

	// 更新项目表单文件
	uploadUpdateFile: (data, params) => {
		if (!data || !params || !params.id)
			throw new Error("Invalid data or params parameter")
		return request(`${moduleName}/${params.id}/edit`, {
			method: "put",
			body: data,
			params
		})
	},

	// 启用/禁用
	disabledFile: id => {
		if (!id) throw new Error("Invalid id parameter")
		return request(`/common/form-file/${id}/enabled-disabled`, {
			method: "post"
		})
	}
}
