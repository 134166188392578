<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="initList" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="文件名称">
				<Input placeholder="请输入文件名称" v-model="searchParams.name" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="更新时间" placement="top">
				<DatePicker
					v-model="createdTime"
					format="yyyy-MM-dd"
					type="daterange"
					:transfer="true"
					:clearable="true"
					placeholder="请选择更新时间"
				>
				</DatePicker>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="false"
		></CtmsDataGrid>
		<FlowView
			:flowId="flowId"
			:fileId="fileId"
			:visible="visible"
			:isNeedMark="isNeedMark"
			@onCancel="visible = false"
		></FlowView>
	</div>
</template>

<script>
import api from "@/api/project/formTemplate"

import moment from "moment"
import { mapState } from "vuex"
import { formDataToMD5 } from "@/utils/util"
import View from "./View.vue"

const { getTemplateList, uploadFile } = api
export default {
	name: "ProjectFormTemplate",
	data() {
		return {
			loading: false,
			columns: [
				{
					title: "文件名",
					key: "name",
					minWidth: 180
				},
				{
					title: "流程名称",
					key: "flowName",
					minWidth: 180
				},
				{
					title: "更新时间",
					key: "procInstUpdateTime",
					minWidth: 120
				},
				{
					title: "更新人",
					key: "procInstUpdateBy",
					minWidth: 120
				},
				{
					title: "操作",
					key: "action",
					width: 120,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{
								label: "查看",
								on: {
									click: () => this.showViewDrawer(params, 1)
								}
							}
						]
						if (
							this.projectActionPermissions.indexOf(
								"btn_project_form_template_down"
							) === -1
						) {
							actionList[1] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			listData: [],
			searchParams: {
				name: ""
			},
			oldSearchParams: {},
			createdTime: [],
			filetype: "docx",
			visible: false,
			flowId: "",
			fileId: "",
			isNeedMark: 1
		}
	},
	components: {
		FlowView: View
	},
	computed: {
		...mapState("project", ["infoHeight"]),
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 146 - this.infoHeight
			}
		}),
		...mapState("permission", ["projectActionPermissions"])
	},
	methods: {
		changeDt(v) {
			console.log(v)
		},
		// 重置
		reset() {
			this.searchParams = {
				name: ""
			}
			this.createdTime = []
			this.initList()
		},
		async initList() {
			this.loading = true
			const startDate =
				(this.createdTime[0] &&
					moment(this.createdTime[0]).format("YYYY-MM-DD")) ||
				""
			const endDate =
				(this.createdTime[1] &&
					moment(this.createdTime[1]).format("YYYY-MM-DD")) ||
				""
			const res = await getTemplateList({
				projectId: this.$route.params.projectId,
				...this.searchParams,
				startDate,
				endDate
			})
			if (res) {
				this.listData = res.data
			}
			this.loading = false
		},
		showViewDrawer({ row }, isNeedMark) {
			const { flowId, id } = row
			this.visible = true
			this.flowId = flowId
			this.fileId = id
			this.isNeedMark = isNeedMark
		},
		// md5
		getMD5(file) {
			// if (!this.filetype.includes(file.type)) {
			//   this.$Message.error('只允许上传格式为doc！');
			//   return false;
			// }
			// if (file.size > 1024 * 1024 * 10) {
			//   this.$Message.error('文件大小不能超过10M！');
			//   return false;
			// }
			formDataToMD5(file, md5Str => {
				this.onUploadFile(file, md5Str)
			})
			return false
		},
		// 上传文件
		onUploadFile(file, md5) {
			const fd = new FormData()
			fd.append("file", file)
			// fd.append('projectId ', this.$route.params.projectId);
			// fd.append('md5 ', md5);
			const params = {
				md5,
				projectId: this.$route.params.projectId
			}
			uploadFile(fd, params).then(res => {
				if (res) {
					this.$Message.success("上传成功!")
					this.initList()
				}
			})
		},
		handlePrint({ row }) {
			console.log(row)
		},
		handleDownload({ row }) {
			console.log(row)
		}
	},
	mounted() {
		this.initList()
	}
}
</script>

<style scoped></style>
