<template>
	<div class="content-wrap">
		<Spin fix v-if="loading || initLoading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="入组时间" placement="top">
				<DatePicker
					type="daterange"
					placeholder="请输入入组时间"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="研究对象编号" placement="top">
				<Input
					v-model="searchParams.projectObjectNo"
					clearable
					placeholder="请输入研究对象编号"
				></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="研究对象标签">
				<Select
					v-model="searchParams.projectObjectLabel"
					:transfer="true"
					placeholder="请选择研究对象标签"
          clearable
				>
          <Option value="门诊" label="门诊"></Option>
          <Option value="住院" label="住院"></Option>
          <Option value="纳入" label="纳入"></Option>
          <Option value="排除" label="排除"></Option>
          <Option value="剔除" label="剔除"></Option>
          <Option value="脱落" label="脱落"></Option>
          <Option value="终止" label="终止"></Option>
          <Option value="已入组" label="已入组"></Option>
          <Option value="未入组" label="未入组"></Option>
          <Option value="筛选期" label="筛选期"></Option>
          <Option value="治疗期" label="治疗期"></Option>
          <Option value="随访期" label="筛随访期选期"></Option>
          <Option value="完成" label="完成"></Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<div style="color: #cf6863;">
				本月入组研究对象： <span style="font-size: 18px; font-weight: bold; vertical-align: middle;">{{ groupNumber }}人</span>
			</div>
			<!-- <Button @click="exportLogs">导出操作记录</Button> -->
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
			ref="table"
		></CtmsDataGrid>
	</div>
</template>

<script>
import researchObjectManage from "@/api/project/researchObjectManage/researchObjectManage"
import { color } from "echarts"
import { mapState } from "vuex"

const {
	apiGetPageList,
  getProjectGroupNumber
} = researchObjectManage

export default {
	name: "index",
	data() {
		return {
      groupNumber:0,
			colorList: {
				'门诊': '#de868f',
				'住院': '#de868f',
				'已入组': '#0f40f5',
				'未入组': '#bebebe',
				'筛选期': '#119aff',
				'治疗期': '#2d6187',
				'随访期': '#fcca00',
				'完成': '#e99d42',
				'纳入': '#a2ef4d',
				'排除': '#bd3124',
				'剔除': '#bd3124',
				'脱落': '#bd3124',
				'终止': '#bd3124'
			},
			listData: [{operatorTime:1}],
			columns: [
				{
					title: "研究对象",
					key: "patientName",
					minWidth: 120
				},
				{
					title: "研究对象编号",
					key: "projectObjectNo",
					minWidth: 180
				},
				{
					title: "就诊流水号",
					key: "registrationNumber",
					minWidth: 120
				},
				{
					title: "身份证",
					key: "idCard",
					minWidth: 180
				},
				{
					title: "手机号",
					key: "mobilePhone",
					minWidth: 120
				},
				{
					title: "门诊号",
					key: "outpatientNumber",
					minWidth: 120
				},
				{
					title: "住院号",
					key: "admissionNo",
					minWidth: 120
				},
				{
					title: "入组时间",
					key: "groupTime",
					minWidth: 120
				},
				{
					title: "研究对象标签",
					key: "tags",
					minWidth: 300,
					fixed: "right",
					align: 'center',
          render: (h, params) => {
          return h(
            "div",
            {},
            params.row.tags.map(tag => h("span", {
                attrs: {
                  class: "option_item_operate"
                },
                style: {
                    backgroundColor: this.colorList[tag]
                }
            }, tag))
            )
          }
				}
			],
			loading: false,
			initLoading: false,
			addVisible: false,
			viewVisible: false,
			updateId: "",
			viewId: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			typeList: [],
			projectList: [],
			times: [],
			searchParams: {
				projectObjectNo: "",
				projectObjectLabel: "",
  				projectId: this.$route.params.projectId
					? this.$route.params.projectId
					: "",
				groupStartTime: this.times ? this.times[0] : "",
				groupEndTime: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
				projectId: this.$route.params.projectId
					? this.$route.params.projectId
					: ""
			}
		}
	},
	created() {
		this.initList()
	},
	computed: {
		...mapState("project", ["infoHeight", "projectInfo"]),
		...mapState({
			dataGridHeight(state) {
				if (this.$route.params.projectId) {
					return state.contentHeight - 146 - this.infoHeight
				}
				return state.contentHeight - 130
			}
		})
	},
	methods: {
		// 将字符串转换为数组
		splitToArray(value) {
			if (!value || value === 'null' || value === 'undefined') return [];
			return typeof value === 'string' && value.includes(',')
				? value.split(',').filter(Boolean)
				: [value];
		},
		initList() {
      this.$asyncDo(async () => {
        const res = await getProjectGroupNumber({
          ...this.oldSearchParams
        })
        if (res) {
          this.groupNumber = res.data.groupNumber
        }
      })
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPageList({
					...this.oldSearchParams,
					page: this.page.current,
					limit: this.page.pageSize
				})
				if (res) {
					this.listData = res.data
          console.log(this.listData)
          this.listData.forEach((item, index) => {
            const { projectObjectStage, groupFlag, sourceType, projectObjectStatus } = item
            console.log(projectObjectStage, groupFlag, sourceType, projectObjectStatus)
            // 将每个字段转换为数组，同时处理空值/未定义值]
            const groupFlagArr = this.splitToArray(groupFlag)
            const projectObjectStageArr = this.splitToArray(projectObjectStage)
            const projectObjectStatusArr = this.splitToArray(projectObjectStatus)
            const sourceTypeArr = this.splitToArray(sourceType)

            // 合并所有数组
            this.listData[index].tags = [...groupFlagArr, ...projectObjectStageArr, ...projectObjectStatusArr, ...sourceTypeArr]
          })
					this.page.total = res.totalCount
				}
				this.loading = false
			})
		},
		// 导出操作记录
		exportLogs() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPageList({
					projectId: this.$route.params.projectId
						? this.$route.params.projectId
						: "",
					limit: 0,
					page: 10 * 10000
				})
				if (res) {
					console.log(res)
				}
				this.loading = false
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.isShowprojectObjectLabel = false
			this.times = []
			this.searchParams = {
				projectObjectNo: "",
				projectObjectLabel: "",
				projectId: this.$route.params.projectId
					? this.$route.params.projectId
					: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			Object.assign(this.searchParams, {
				groupStartTime: this.times ? this.times[0] : "",
				groupEndTime: this.times ? this.times[1] : ""
			})
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	},
	watch: {}
}
</script>

<style lang="less">
  .option_item_operate{
	margin-left: 5px;
    display: inline-block;
    padding: 2px 6px;
    border-radius: 3px;
    color: #fff;
  }
</style>
