import request from "@/utils/request"

const moduleName = "Ctms"
export default {
	// 获取研究对象列表
	apiGetPageList: data =>
		request(`${moduleName}/krProjectObject/pageList`, {
			method: "post",
			params: data,
			body: data
		}),
  // 获取研究对象列表(项目内)
  getProjectGroupNumber: data =>
    request(`${moduleName}/krProjectObject/getProjectGroupNumber`, {
      method: "get",
      params: data
    })
}
